<mat-drawer-container class="float-left" *ngIf="viewReady">
  <mat-drawer mode="side" [opened]="subNavView.view$ | async">
    <app-sub-navbar
      *ngIf="subNavView.view$ | async"
      class="float-left h-100"
      [ngClass]="
        subNavView.view$.getValue() === pagesPATH.PALLET
          ? 'sub-nav-bar-small'
          : 'sub-nav-bar-big'
      "
      (indexBoxUpdate)="updateIndexBox($event)"
    >
    </app-sub-navbar>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content">
      <div
        *ngIf="
          palletPosition === 1 && !(subNavView.patternBoxPositions$ | async)
        "
        class="robot-arm"
      >
        <img src="../../../assets/illustrative/GenericTop.png" />
      </div>
      <div
        *ngIf="boxesInLayer"
        class="box-packing-wrapper"
        [ngClass]="{
          'set-margin-left':
            (subNavView.view$ | async) === 'boxEdit' &&
            (subNavView.patternBoxPositions$ | async)
        }"
      >
        <box-packing
          class="box-packing-edit"
          flip-y-axis
          #boxPacking
          allow-collisions
          [boxes]="boxesInLayer"
          [frameWidth]="frameWidth"
          [frameLength]="frameLength"
          [boxPadding]="boxPadding"
          [boxOverflowX]="boxOverflowX"
          [boxOverflowY]="boxOverflowY"
          [autoalignDistance]="autoalignDistance"
          (selection)="onSelection($event)"
          (edit)="edit($event)"
          [ngStyle]="{ 'aspect-ratio': aspectRatio }"
        >
        </box-packing>

        <div class="items">
          <div
            [class.hide]="
              (!inverseApproach && palletPosition === 1) ||
              (inverseApproach && palletPosition === 0)
            "
            class="packing-info"
          >
            <div>
              <mat-icon color="primary" style="transform: rotate(135deg)"
                >arrow_right_alt</mat-icon
              >
              <mat-icon color="primary" style="transform: rotate(135deg)"
                >arrow_right_alt</mat-icon
              >
            </div>
            <span>Approach Direction</span>
          </div>

          <div class="packing-info">
            <div>
              <mat-icon color="primary">keyboard_arrow_up</mat-icon>
            </div>
            <span>Front of Pallet</span>
          </div>

          <div
            [class.hide]="
              (inverseApproach && palletPosition === 1) ||
              (!inverseApproach && palletPosition === 0)
            "
            class="packing-info"
          >
            <div>
              <mat-icon color="primary" style="transform: rotate(45deg)"
                >arrow_right_alt</mat-icon
              >
              <mat-icon color="primary" style="transform: rotate(45deg)"
                >arrow_right_alt</mat-icon
              >
            </div>
            <span>Approach Direction</span>
          </div>
        </div>
      </div>
      <div
        *ngIf="palletPosition === 0"
        [ngClass]="
          (subNavView.patternBoxPositions$ | async)
            ? 'robotHidden'
            : 'robotVisible'
        "
        style="height: 45vh"
      >
        <img
          style="height: inherit"
          src="../../../assets/illustrative/UR10eTop.png"
        />
      </div>
    </div>
  </mat-drawer-content>

  <mat-drawer
    #drawerBoxPositions
    mode="side"
    position="end"
    [opened]="subNavView.patternBoxPositions$ | async"
    style="width: 22%"
  >
    <h3 style="text-align: center">Selected Boxes:</h3>
    <div>
      <mat-list>
        <mat-list-item
          *ngFor="let box of selectedBoxes"
          style="height: 40px"
          class="box_position col-md-12"
        >
          <h5 class="col-md-2">{{ box.getId() + 1 }}:</h5>
          <mat-form-field class="col-md-5 float-left">
            <span matPrefix>X: &nbsp;</span>
            <input
              style="text-align: right"
              matInput
              type="number"
              [value]="box.getXCenter()"
              (input)="updateX($any($event.target).value, box)"
            />
          </mat-form-field>
          <mat-form-field class="col-md-5 float-left">
            <span matPrefix>Y: &nbsp;</span>
            <input
              style="text-align: right"
              matInput
              type="number"
              [value]="box.getYCenter()"
              (input)="updateY($any($event.target).value, box)"
            />
          </mat-form-field>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-drawer>
</mat-drawer-container>

<app-wizard-bottom-navbar
  class="bottom-toolbar"
  [title]="subNavView.title$ | async"
  primaryButton="confirm"
  secondaryButton="cancel"
  (nextClick)="save()"
  (backClick)="dialogRef.close()"
></app-wizard-bottom-navbar>
