import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { first, Observable, of, switchMap } from 'rxjs';
import { settings } from '../models_new/config/application-settings';
import { StateService } from './state.service';
import { RobotBrandType } from '../models_new/enums/robot-brand-types';

@Injectable({
  providedIn: 'root',
})
export class BrandsGuard {
  constructor(private router: Router, private stateService: StateService) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.stateService.activeBrands$.pipe(
      first(),
      switchMap((brands: RobotBrandType[]) => {
        // If no brands are selected, allow access to all routes
        if (!brands || !brands.length) {
          return of(true);
        }

        const urlArray = _state.url.split('/');
        let match = false;

        // Check if settings.brandsRoutes[brand] includes some of urlArray
        for (const brand of brands) {
          const brandRoute = settings.brandsRoutes[brand];
          if (brandRoute.some((route) => urlArray.includes(route))) {
            match = true;
            break;
          }
        }

        if (!match) {
          // Route back to the landing page if brand and route mismatch
          this.router.navigate(['/']);
        }

        return of(match);
      })
    );
  }
}
