import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {
  combineLatest,
  combineLatestWith,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  skipWhile,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ApiInstalledRobot } from 'src/app/models_new/classes/api-models/ApiRobotInstallation';
import { InstalledRobotsApiService } from 'src/app/services/api/robot-installations-api.service';
import { ITableData } from '../../gui/table/table.component';
import { HardwareUtils } from 'src/app/utils/hardware-utils';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { LicenseApiService } from 'src/app/services/api/license-api.service';
import { ILicenseLinkInfo } from 'src/app/models_new/classes/api-models/ApiSoftwareLicense';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { IApiGenerateWaypoint } from 'src/app/models_new/classes/api-models/ApiGeneratedWaypoint';
import { WaypointsApiService } from 'src/app/services/api/waypoints-api.service';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/services/dialog.service';
import { IrLicenseComponent } from '../../dialogs/ir-license/ir-license.component';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { RobotBackupAssistantService } from 'src/app/services/robot-backup-assistant.service';
import { CalibrationsApiService } from 'src/app/services/api/calibrations-api.service';
import { RobotType } from 'src/app/models_new/types/robot-type';
import { NotificationService } from 'src/app/services/notification.service';
import { FileUtils } from 'src/app/utils/file-utils';
import {
  InventoryTableCta,
  InvetoryTableAction,
} from '../../gui/inventory-table/inventory-table.component';
import { FilterTableData } from 'src/app/models_new/types/sorting-option';
import { SortDirection } from '@angular/material/sort';
import { ObjectUtils } from 'src/app/utils/object';
import {
  ApiCalibration,
  IApiCalibration,
} from 'src/app/models_new/classes/api-models/ApiCalibration';
import {
  CalibrationTableData,
  tableConfigurations,
} from './robot-entity.table-config';
import {
  ISimulationTableItem,
  SimulationApiService,
} from 'src/app/services/api/simulation-api.service';
import { IStartSimulation } from 'src/app/services/api/api.service';
import { NewRobotDialogComponent } from '../../dialogs/new-robot-dialog/new-robot-dialog.component';
import { StateService } from 'src/app/auth/state.service';
import { RobotBrandType } from 'src/app/models_new/enums/robot-brand-types';
import { downloadAsFile } from 'src/app/utils/download-as-file';
import { AssetApiService } from 'src/app/services/api/asset-api.service';

@Component({
  selector: 'app-robot-entity',
  templateUrl: './robot-entity.component.html',
  styleUrls: ['./robot-entity.component.scss'],
})
export class RobotEntityComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  robot$: Observable<DataRequestState<ApiInstalledRobot>>;
  org_id$: Observable<string>;
  robotTypeState: RobotType;
  robotModel: string;
  pallyVersion: string;
  license$: Observable<ILicenseLinkInfo>;

  pallyPrograms$: Observable<DataRequestState<IApiGenerateWaypoint[]>>;
  blockSelectedPallyPrograms: IApiGenerateWaypoint[] = [];
  tableFilterPallyPrograms: FilterTableData = new FilterTableData();
  orderByPallyPrograms: { column: string; order: SortDirection };

  calibrations$: Observable<DataRequestState<CalibrationTableData[]>>;
  blockSelectedCalibrations: IApiCalibration[] = [];
  tableFilterCalibrations: FilterTableData = new FilterTableData();
  orderByCalibrations: { column: string; order: SortDirection };

  simulations$: Observable<DataRequestState<ISimulationTableItem[]>>;
  blockSelectedSimulations: ISimulationTableItem[] = [];
  tableFilterSimulations: FilterTableData = new FilterTableData();
  orderBySimulations: { column: string; order: SortDirection };
  restoreFiltersSimulations: boolean = null;

  objUtil = ObjectUtils;

  specs: ITableData[] = [];

  displaySpecs = tableConfigurations.displaySpecs;
  rowActionsPallyPrograms = tableConfigurations.rowActionsPallyPrograms;
  displayedPallyProgramsColumns =
    tableConfigurations.displayedPallyProgramsColumns;
  sortingColumnsPallyPrograms = tableConfigurations.sortingColumnsPallyPrograms;
  rowCtaPallyPrograms = tableConfigurations.rowCtaPallyPrograms;
  rowActionsCalibrations = tableConfigurations.rowActionsCalibrations;
  displayedCalibrationsColumns =
    tableConfigurations.displayedCalibrationsColumns;
  sortingColumnsCalibrations = tableConfigurations.sortingColumnsCalibrations;
  rowCtaCalibrations = tableConfigurations.rowCtaCalibrations;
  displayedColumnsSimulations = tableConfigurations.displayedSimulationsColumns;
  rowActionsSimulations = tableConfigurations.rowActionsSimulations;
  displayedSimulationsColumns = tableConfigurations.displayedSimulationsColumns;
  sortingColumnsSimulations = tableConfigurations.sortingColumnsSimulations;
  rowCtaSimulations = tableConfigurations.rowCtaSimulations;
  resultFilterSimulations = tableConfigurations.resultFilterSimulations;

  missingConfigurationTitle =
    'This digital twin robot is not yet ready for use.';
  missingConfigurationMessage =
    'Please upload the Pally backup file from the robot to enable its functionality.';
  missingConfigurationAction = 'Upload backup file';

  constructor(
    private router: Router,
    private irApiService: InstalledRobotsApiService,
    private route: ActivatedRoute,
    private licenseApi: LicenseApiService,
    private waypointsApi: WaypointsApiService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private rbaService: RobotBackupAssistantService,
    private calibrationsApi: CalibrationsApiService,
    private notification: NotificationService,
    private simulationsApi: SimulationApiService,
    private stateService: StateService,
    private assetService: AssetApiService
  ) {
    this.stateService.activeBrands$
      .pipe(startWith(null), takeUntil(this.destroy$))
      .subscribe({
        next: (brands) => {
          if (brands && brands.length) {
            if (
              brands.includes(RobotBrandType.DOOSAN) ||
              !brands.includes(RobotBrandType.UR)
            ) {
              this.missingConfigurationTitle =
                'This digital twin robot is not yet ready for use.';
              this.missingConfigurationMessage =
                'Please upload the Pally calibration file from the robot to enable its functionality.';
              this.missingConfigurationAction = 'Upload calibration file';
            }
            if (
              brands.includes(RobotBrandType.UR) ||
              !brands.includes(RobotBrandType.DOOSAN)
            ) {
              this.missingConfigurationTitle =
                'This digital twin robot is not yet ready for use.';
              this.missingConfigurationMessage =
                'Please upload the Pally backup file from the robot to enable its functionality';
              this.missingConfigurationAction = 'Upload backup file';
            }
          }
        },
        error: (error) => {
          console.error('Error in activeBrands$', error);
        },
      });

    this.org_id$ = this.route.data.pipe(
      map(
        (data: Data) =>
          (data as IOrganizationContextResolverData).organization_id
      ),
      takeUntil(this.destroy$)
    );

    this.robot$ = this.irApiService
      .fetchInstalledRobotsByID(this.route.snapshot.paramMap.get('id'), false)
      .pipe(
        tap((r) => {
          this.robotTypeState = HardwareUtils.getRobotBrand(
            r.robot_serial_number
          );

          this.robotModel = HardwareUtils.getRobotModelBySerialNumber(
            r.robot_serial_number
          );

          this.displaySpecs.forEach((key) => {
            let value = r[key] || 'N/A';

            if (key === 'created_at' || key === 'updated_at') {
              value =
                this.datePipe.transform(r[key], 'dd/MM/yyyy HH:mm') || 'N/A';
            }

            if (key === 'robot_brand') {
              value = this.robotTypeState;
            }

            if (key === 'robot_type') {
              value = this.robotModel;
            }

            if (key === 'pally_version') {
              if (this.robotTypeState === 'UR') {
                value =
                  r.robot_configuration[0]?.strategy.data.software
                    .pally_version || 'N/A';
              } else if (this.robotTypeState === 'DOOSAN') {
                value = r.installed_robots_calibration.pally_version || 'N/A';
              }
              this.pallyVersion = value || 'N/A';
            }

            if (key === 'os_version') {
              if (this.robotTypeState === 'UR') {
                value =
                  'Polyscope ' +
                    r.robot_configuration[0]?.strategy.data.software
                      .polyscope_version || 'N/A';
              }
            }
            this.specs.push({
              data: {
                name: key.charAt(0).toUpperCase() + key.slice(1),
                value: value,
              },
              actions: [],
            });
          });
        }),
        takeUntil(this.destroy$),
        toRequestState(),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.calibrations$ = combineLatest([
      this.org_id$,
      this.robot$.pipe(
        filter((r) => r.value !== null && r.value !== undefined),
        map((r) => r.value)
      ),
    ]).pipe(
      switchMap(([org_id, robot]) =>
        combineLatest([
          of(robot),
          this.calibrationsApi.subscribeCalibrationByRobotId(robot.id, org_id),
        ])
      ),
      map(([robot, calibrations]) => {
        let configurations: CalibrationTableData[] = [];
        if (this.robotTypeState === 'DOOSAN') {
          // Doosan - Calibrations
          calibrations.map((c: ApiCalibration, indx: number) => {
            configurations.push({
              id: c.id,
              name: robot.name + ' calibration ' + (calibrations.length - indx),
              active:
                robot.installed_robots_calibration?.id === c.id
                  ? 'Active'
                  : 'Not active',
              updated_at: this.datePipe.transform(
                c.updated_at,
                'dd/MM/yyyy HH:mm'
              ),
              serial_no: robot.robot_serial_number,
              pally_version: robot.installed_robots_calibration.pally_version,
              conveyor_type_1: c.conveyor_type_1,
              conveyor_type_2: c.conveyor_type_2,
              data: c.data,
            });
            if (indx === 0 && c.data?.file_info?.pally_version) {
              this.pallyVersion = c.data.file_info.pally_version || 'N/A';
            }
          });
        } else if (this.robotTypeState === 'UR') {
          // UR - Configurations
          const parseConfig = (config) => ({
            id: config.id,
            name: config.name,
            active:
              config.id === robot.robot_configuration_id
                ? 'Active'
                : 'Not active',
            updated_at: this.datePipe.transform(
              config.updated_at,
              'dd/MM/yyyy HH:mm'
            ),
            conveyor_type_1: config.scene.data.conveyors[0]?.type,
            conveyor_type_2: config.scene.data.conveyors[1]?.type,
            data: {
              ...config,
              file_info: {
                serial_no: robot.robot_serial_number,
                pally_version: config.strategy.data.software.pally_version,
              },
            },
          });
          if (Array.isArray(robot.robot_configuration)) {
            // If it is an array, map over it
            robot.robot_configuration.map((config) => {
              configurations.push(parseConfig(config));
            });
          } else {
            // If it's not an array, treat it as a single object
            configurations.push(parseConfig(robot.robot_configuration));
          }
        }
        return configurations;
      }),
      takeUntil(this.destroy$),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.license$ = this.org_id$.pipe(
      combineLatestWith(this.robot$.pipe(skipWhile((r) => !r.value))),
      switchMap(([org_id, robot]) =>
        this.licenseApi.getValidLicenseForRobotSerialNumber(
          org_id,
          robot.value.robot_serial_number
        )
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.simulations$ = this.robot$.pipe(
      filter((r) => r.value !== null),
      map((r) => {
        const simulations = r.value.robot_configuration[0]?.scene?.simulations;
        simulations?.map((sim: ISimulationTableItem) => {
          const maxNameLength = 30;
          if (sim.name.length > maxNameLength) {
            sim.nameSlice = sim.name.slice(0, maxNameLength) + '...';
          } else {
            sim.nameSlice = sim.name;
          }

          sim.updated_at =
            this.datePipe.transform(sim.updated_at, 'dd/MM/yyyy HH:mm') ||
            'N/A';
          // Remove _ from status
          sim.simulation_state = sim.simulation_state.replace(/_/g, ' ') as any;

          // Remove "robot" from status
          sim.simulation_state = sim.simulation_state.replace(
            /robot/gi,
            ''
          ) as any;

          if (sim.simulation_status) {
            if (!sim.simulation_status.progress) {
              sim.simulation_status.progress = '0%';
            } else {
              sim.simulation_status.progress =
                sim.simulation_status.progress + '%';
            }
          }
          sim.patterns = [sim.pattern, sim.second_pattern].filter(
            (pattern) => pattern
          );
          sim.inventoryCpmCalc =
            (sim.simulation_status?.calculated?.cpm || '-') +
            (' (' + sim.cpm_requested + ')' || '(-)');

          return sim;
        });

        return simulations;
      }),
      takeUntil(this.destroy$),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );

    this.pallyPrograms$ = this.org_id$.pipe(
      combineLatestWith(this.robot$.pipe(skipWhile((r) => !r.value))),
      switchMap(([org_id, robot]) =>
        this.waypointsApi.fetchWaypointsByInstalledRobotId(
          robot.value.id,
          org_id
        )
      ),
      map((waypoints) => {
        waypoints.map((w) => {
          w.updated_at =
            this.datePipe.transform(w.updated_at, 'dd/MM/yyyy HH:mm') || 'N/A';
          // Remove _ from status
          w.simulation_state = w.simulation_state.replace(/_/g, ' ') as any;

          if (!w.progress) {
            w.progress = '0%';
          } else {
            w.progress = w.progress + '%';
          }
        });

        return waypoints;
      }),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  navigateBack(): void {
    this.router.navigate(['installed-robots']);
  }

  onSimulationClick() {
    this.robot$
      .pipe(
        take(1),
        map((r) => r.value.robot_configuration[0].id)
      )
      .subscribe((id) => {
        this.router.navigate([
          pagesPATH.SIMULATIONS,
          pagesPATH.INSTALLED_ROBOT_SIMULATION_WIZARD,
          id,
        ]);
      });
  }

  navigateToGenerateWaypoint() {
    this.router.navigate([pagesPATH.WAYPOINTS, 'new'], {
      queryParams: { robot_id: this.route.snapshot.paramMap.get('id') },
    });
  }

  navigateToWaypointFlow(programId: string) {
    if (programId === 'new') this.router.navigate([pagesPATH.WAYPOINTS, 'new']);
    else this.router.navigate([pagesPATH.WAYPOINTS, programId]);
  }

  viewLicense(robot_serial_number: string) {
    this.dialogService
      .showCustomDialog(IrLicenseComponent, DialogSize.MEDIUM, null, {
        robot: {
          installed_robot_sw_license: {
            robot_serial_number: robot_serial_number,
          },
        },
      })
      .afterClosed();
  }

  downloadLicense(license: ILicenseLinkInfo) {
    this.notification.showMessage('Downloading license', 5000, true);
    if (this.robotTypeState === 'DOOSAN') {
      FileUtils.downloadJson(
        JSON.parse(license.generated_license),
        `no.rocketfarm.pal.${license.robot_serial_number}.license`
      );
    } else if (this.robotTypeState === 'UR') {
      downloadAsFile(
        `no.rocketfarm.urcap.palletmanager.${license.robot_serial_number}.license`,
        license.generated_license
      );
    } else {
      this.notification.showError('Unknown robot type');
    }
  }

  requestLicense(robot_serial_number: string) {
    this.dialogService
      .showStandardDialog({
        content: {
          title: 'Request license',
          contentAsString: `Please mail: license@rocketfarm.no. Robot serial number: ${robot_serial_number}`,
        },
        gui: {
          size: DialogSize.MEDIUM,
          autoHeight: true,
        },
        button: {
          enableCancel: false,
          text: 'Ok',
        },
      })
      .afterClosed();
  }

  actionClickedSimulations(e: {
    action: InvetoryTableAction;
    row: ISimulationTableItem;
  }) {
    switch (e.action.actionId) {
      case 'start':
        this.startSimulation(e.row.id);
        break;
      case 'retry':
        this.retrySimulation(e.row);
        break;
      case 'delete':
        this.onDeleteSimulations([e.row]);
        break;
      case 'get_pattern_json':
        if (e.row.pattern) {
          const pattern = e.row.pattern;
          /**
           * @desc When exporting JSON from MRC, it is advised to remove or replace whitespace in the file name to
           * ensure compatibility with older versions of Pally URCap that cannot handle patterns with whitespace in the file name.
           * {@link https://rocketfarm.atlassian.net/browse/PALLY-3921?focusedCommentId=40602 Learn more} about this issue.
           */
          pattern.data.name = pattern.name = pattern.name.replace(/ /g, '_');
          FileUtils.downloadJson(pattern.data, pattern.name);
        }
        break;
    }
  }

  private retrySimulation(simulation: ISimulationTableItem) {
    this.router.navigate([
      pagesPATH.SIMULATIONS,
      pagesPATH.SIMULATION_RETRY,
      simulation.id,
    ]);
  }

  private startSimulation(id: string) {
    this.simulationsApi
      .startSimulation(id)
      .pipe(take(1))
      .subscribe((res: IStartSimulation) => {
        if (res && res.simulation_state === 'Queued') {
          this.notification.showSuccess(res.simulation_state);
        } else {
          this.notification.showError('Failed to start simulation!');
          console.error('Failed to start simulation', res);
        }
      });
  }

  ctaClickedSimulations(e: {
    action: InventoryTableCta;
    row: ISimulationTableItem;
  }) {
    switch (e.action.actionId) {
      case 'retry':
        this.retrySimulation(e.row);
        break;
    }
  }

  rowClickedSimulations(simulation: ISimulationTableItem) {
    this.router.navigate([pagesPATH.SIMULATIONS, simulation.id]);
  }

  onDeleteSimulations(simulations: ISimulationTableItem[]) {
    this.notification
      .deletePrompt(
        'Delete',
        'simulation',
        simulations.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          const operations = simulations
            .map((m) => m.id)
            .map((id) => this.simulationsApi.deleteSimulationById(id));
          return forkJoin(operations);
        })
      )
      .subscribe((result) => {
        if (result === null) {
          this.notification.showError(
            'Delete failed. Are you the owner of this item, and do you have the correct role in your organization?'
          );
        } else {
          this.notification.showMessage(
            `The simulation was deleted successfully`
          );
          this.restoreFiltersSimulations = true;
          this.tableFilterSimulations = new FilterTableData();
          setTimeout(() => (this.restoreFiltersSimulations = null), 100);
        }
      });
  }

  initializeRobot() {
    this.dialogService
      .showCustomDialog(
        NewRobotDialogComponent,
        DialogSize.MEDIUM,
        null,
        null,
        true
      )
      .afterClosed()
      .pipe(
        skipWhile((result) => !result),
        switchMap((result) =>
          combineLatest([this.robot$, this.org_id$, of(result)])
        ),
        switchMap(([robot, org_id, result]) => {
          const robotName = result.find((f) => f.name === 'name').value;
          return this.rbaService.uploadBackupGeneric({
            orgId: org_id,
            name: robotName,
            updateRobotId: robot.value.id,
          });
        }),
        take(1)
      )
      .subscribe();
  }

  uploadConfiguration() {
    if (this.robotTypeState === 'Unknown Robot type') {
      this.initializeRobot();
    } else {
      this.robot$
        .pipe(
          filter((r) => r.value !== null),
          take(1),
          combineLatestWith(this.org_id$),
          switchMap(([robot, org_id]) => {
            let strictFileType: 'json' | 'zip' =
              this.robotTypeState === 'UR' ? 'zip' : 'json';
            return this.rbaService.uploadBackupGeneric({
              orgId: org_id,
              name: robot.value.name,
              updateRobotId: robot.value.id,
              strictFileType: strictFileType,
            });
          }),
          take(1)
        )
        .subscribe();
    }
  }

  calibrationsActionClicked(e: {
    action: InvetoryTableAction;
    row: IApiCalibration;
  }) {
    if (e.action.actionId === 'delete_calibrations') {
      this.onDeleteCalibrations([e.row]);
    } else {
      console.warn('Unknown action;', e.action);
    }
  }

  calibrationsCtaClicked(e: {
    action: InventoryTableCta;
    row: IApiCalibration;
  }) {
    if (e.action.actionId === 'download_calibrations') {
      if (e.row.data === null) {
        this.notification.showError('No calibration found!');
        return;
      }
      if (this.robotTypeState === 'DOOSAN') {
        const progressNotificaiton = this.notification.showMessage(
          'Downloading backup file...',
          'infinity',
          true
        );
        this.robot$.pipe(take(1)).subscribe((robot) => {
          const formatDate = this.datePipe.transform(
            e.row.created_at,
            'dd/MM/yyyy'
          );
          FileUtils.downloadJson(
            e.row.data,
            `${this.robotTypeState}_${robot.value.robot_serial_number}_${formatDate}`
          );
          if (progressNotificaiton) {
            this.notification.deleteMessage(progressNotificaiton);
          }
          this.notification.showSuccess(
            'Backup file downloaded successfully!',
            5000,
            true
          );
        });
      } else if (this.robotTypeState === 'UR') {
        const progressNotificaiton = this.notification.showMessage(
          'Downloading backup file...',
          'infinity',
          true
        );
        this.robot$
          .pipe(
            switchMap((robot) =>
              combineLatest([
                this.assetService.getAsset(robot.value.backup_zip_id),
                of(robot),
              ])
            ),
            map(([backupAsset, robot]) =>
              FileUtils.base64ToFile(
                backupAsset,
                `${this.robotTypeState}_${robot.value.name}_backup.zip`,
                'application/zip'
              )
            )
          )
          .subscribe({
            next: (zip) => {
              FileUtils.downloadBlobFile(zip, zip.name);
              if (progressNotificaiton) {
                this.notification.deleteMessage(progressNotificaiton);
              }
              this.notification.showSuccess('Backup files downloaded!');
            },
            error: (_err) => {
              if (progressNotificaiton) {
                this.notification.deleteMessage(progressNotificaiton);
              }
              this.notification.showError('Failed to download backup file.');
            },
          });
      }
    }
  }

  onDeleteCalibrations(calibrations: IApiCalibration[]) {
    this.deleteCalibrationsAction(calibrations)
      .pipe(take(1))
      .subscribe((result) => {
        if (result[0]) {
          this.notification.showMessage(
            'The calibration was deleted successfully!'
          );
        } else {
          this.notification.showError(
            'Unable to delete calibration! Do you have the correct user privilege in your organization?'
          );
        }
      });
  }

  pallyProgramsCtaClicked(e: {
    action: InventoryTableCta;
    row: IApiGenerateWaypoint;
  }) {
    if (e.action.actionId === 'download_waypoints') {
      // Remove .json from name
      e.row.name = e.row.name.replace('.json', '');

      if (!e.row.result) {
        this.notification.showError(
          'The pally program file is not available!',
          5000,
          true
        );
        return;
      }

      this.notification.showMessage(
        'The pally program file is being downloaded!',
        5000,
        true
      );
      FileUtils.downloadJson(e.row.result, e.row.name);

      this.notification.showSuccess(
        'The pally program file was downloaded successfully!',
        5000,
        true
      );
    }
  }

  pallyProgramsActionClicked(e: {
    action: InvetoryTableAction;
    row: IApiGenerateWaypoint;
  }) {
    if (e.action.actionId === 'delete_waypoints') {
      this.onDeletePallyPrograms([e.row]);
    } else {
      console.warn('Unknown action;', e.action);
    }
  }

  onDeletePallyPrograms(waypoints: IApiGenerateWaypoint[]) {
    this.deleteWaypointsAction(waypoints)
      .pipe(take(1))
      .subscribe((result) => {
        if (result[0]) {
          this.notification.showMessage(
            'The waypoints was deleted successfully!'
          );
        } else {
          this.notification.showError(
            'Unable to delete waypoints! Do you have the correct user privilege in your organization?'
          );
        }
      });
  }

  private deleteWaypointsAction(waypoints: IApiGenerateWaypoint[]) {
    return this.notification
      .deletePrompt(
        'Delete',
        'waypoints',
        waypoints.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          const ids = waypoints.map((wp) => wp.id);
          const operations = {};
          for (let i = 0; i < ids.length; i++) {
            operations[i] = this.waypointsApi.deleteWaypointGeneration(ids[i]);
          }
          return forkJoin(operations);
        })
      );
  }

  private deleteCalibrationsAction(calibrations: IApiCalibration[]) {
    return this.notification
      .deletePrompt(
        'Delete',
        'calibrations',
        calibrations.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          const ids = calibrations.map((cal) => cal.id);
          const operations = {};
          for (let i = 0; i < ids.length; i++) {
            operations[i] = this.calibrationsApi.deleteCalibration(ids[i]);
          }
          return forkJoin(operations);
        })
      );
  }
}
