import { Role } from '../../types/role';

export interface roleMap {
  role: Role;
  actions: RoleAction[];
}

const actions = [
  'default_action',

  /* ----------------------------- Production line ---------------------------- */
  'view_production_line',
  'view_all_production_line',
  'create_production_line',
  'delete_production_line',
  'update_production_line',
  'modify_production_line',
  'create_production_line_product',

  /* --------------------------------- Product -------------------------------- */
  'view_product',
  'view_all_product',
  'pick_product_from_inventory',
  'create_product',
  'delete_product',
  'update_product',
  'modify_product',
  'save_product',
  'edit_product',

  /* --------------------------------- Pattern -------------------------------- */
  'view_pattern',
  'view_all_pattern',
  'create_pattern',
  'delete_pattern',
  'update_pattern',
  'download_json_pattern',
  'upload_pattern',
  'save_pattern',
  'customize_pattern',
  'modify_pattern',

  /* --------------------------------- Project -------------------------------- */
  'view_project',
  'view_all_project',
  'create_project',
  'delete_project',
  'edit_project',
  'create_project_production_line',
  'create_project_simulation',
  'create_project_hardware',
  'create_project_software',
  'create_project_product',
  'create_project_pattern',
  'delete_project_pattern',
  'delete_project_product',

  /* -------------------------------- Hardware -------------------------------- */
  'view_hardware',
  'create_hardware',
  'delete_hardware',
  'update_hardware',
  'create_hardware_type',
  'update_hardware_type',
  'create_organization_hardware_type',
  'delete_organization_hardware_type',
  'edit_organization_hardware_type',
  'delete_hardware_configuration',
  'modify_hardware_configuration',

  /* -------------------------------- Software -------------------------------- */
  'view_software',
  'create_software',
  'delete_software',
  'update_software',

  /* --------------------------- Robot configuration -------------------------- */
  'view_robot_configuration',
  'view_all_robot_configuration',
  'create_robot_configuration',
  'delete_robot_configuration',
  'modify_robot_configuration',
  'upload_robot_configuration',
  'update_robot_configuration',
  'export_robot_configuration',
  'share_robot_configuration',
  'clone_robot_configuration',
  'upload_backup_files_robot_configuration',

  /* ---------------------- Installed robots ---------------------- */
  'view_all_installed_robot',
  'create_installed_robot',
  'delete_installed_robot',
  'create_pally_program',
  'start_simulation',
  'download_backup',
  'show_license',
  'link_sw_license',

  /* ------------------------------- Simulation ------------------------------- */
  'view_simulation_details',
  'view_simulation_values',
  'view_all_simulation',
  'delete_simulation',
  'create_simulation',
  'update_simulation',
  'share_simulation',
  'public_simulation_toggle',
  'insert_simulation_comment',
  'start_simulation',
  'play_simulation',
  'expand_simulation',

  /* ------------------------------ Organization ------------------------------ */
  'view_all_organization',
  'view_all_organization_member',
  'create_organization',
  'create_sales_organization',
  'create_organization_member',
  'delete_organization',
  'update_organization',
  'update_send_lead_email_notification',

  /* ------------------------------- Backoffice ------------------------------- */

  // License:
  'create_license_generation',
  'view_licenses',
  'create_global_notification',

  /* -------------------------- Waypoint generation --------------------------*/
  'view_waypoints',
  'create_waypoints',
  'download_waypoints',
  'delete_waypoints',

  /* ------------- Calibrations (Dependent on Installed robots) ------------- */
  'view_calibrations',
  'create_calibrations',
  'delete_calibrations',
  'download_calibrations',

  /* ------------------------- Pally program settings ------------------------ */
  'view_pally_path_strategies',
  'create_pally_path_strategy',
  'delete_pally_path_strategy',

  /* ---------------------------------- Misc ---------------------------------- */
  'download_pdf_report',
  'download_pallet_report',
  'pallet_back',
  'pallet_save_and_exit',
  'create_inventory_item',
  'view_all_inventory',
  'customers_sort_by',
  'compare',
  'continue_to_customer',
];

export type RoleAction = typeof actions[number];

export const AvailableActions = (role: Role): RoleAction[] => {
  const actionsMap: roleMap[] = [
    {
      role: 'org_admin',
      actions: [
        'default_action',
        'public_simulation_toggle',
        'create_organization_member',
        'view_simulation_details',
        'download_json_pattern',
        'view_simulation_values',
        'pallet_back',
        'customize_pattern',
        'view_all_organization',
        'view_all_robot_configuration',
        'view_all_organization_member',
        'view_all_inventory',
        'customers_sort_by',
        'view_all_installed_robot',
        'view_all_project',
        'view_all_simulation',
        'view_all_product',
        'view_all_pattern',
        'view_production_line',
        'expand_simulation',
        'view_pattern',
        'continue_to_customer',
        'create_organization',
        'download_pallet_report',
        'play_simulation',
        'view_product',
        'view_robot_configuration',
        'download_pdf_report',
        'view_hardware',
        'view_software',
        'modify_pattern',
        'update_organization',
        'update_send_lead_email_notification',
      ],
    },
    {
      role: 'org_delete',
      actions: [
        'default_action',
        'delete_project',
        'delete_product',
        'delete_pattern',
        'delete_production_line',
        'delete_organization',
        'delete_hardware',
        'delete_simulation',
        'delete_project_pattern',
        'delete_project_product',
        'delete_software',
        'delete_organization_hardware_type',
        'view_simulation_details',
        'view_simulation_values',
        'pallet_back',
        'view_all_organization',
        'view_all_robot_configuration',
        'view_all_inventory',
        'customers_sort_by',
        'view_all_installed_robot',
        'view_all_project',
        'view_all_simulation',
        'view_all_product',
        'view_all_pattern',
        'expand_simulation',
        'view_pattern',
        'continue_to_customer',
        'play_simulation',
        'view_product',
        'view_production_line',
        'view_robot_configuration',
        'download_pdf_report',
        'view_hardware',
        'view_software',
        'modify_pattern',
        'delete_installed_robot',
        'delete_hardware_configuration',
        'delete_waypoints',
        'delete_calibrations',
        'delete_pally_path_strategy',
      ],
    },
    {
      role: 'org_edit',
      actions: [
        'default_action',
        'create_project',
        'create_hardware',
        'share_simulation',
        'public_simulation_toggle',
        'create_product',
        'create_pattern',
        'create_production_line',
        'create_project_production_line',
        'create_project_product',
        'create_project_pattern',
        'create_project_simulation',
        'create_production_line_product',
        'create_software',
        'create_project_hardware',
        'create_project_software',
        'create_robot_configuration',
        'create_organization',
        'update_pattern',
        'modify_robot_configuration',
        'delete_robot_configuration',
        'create_hardware_type',
        'create_organization_hardware_type',
        'update_product',
        'update_software',
        'update_hardware',
        'update_production_line',
        'update_hardware_type',
        'update_simulation',
        'update_robot_configuration',
        'start_simulation',
        'play_simulation',
        'view_simulation_details',
        'download_json_pattern',
        'upload_pattern',
        'view_simulation_values',
        'modify_production_line',
        'pallet_back',
        'pallet_save_and_exit',
        'customize_pattern',
        'save_pattern',
        'view_all_organization',
        'view_all_production_line',
        'view_all_robot_configuration',
        'view_all_organization_member',
        'view_all_inventory',
        'create_inventory_item',
        'customers_sort_by',
        'view_all_installed_robot',
        'view_all_project',
        'view_all_simulation',
        'view_all_product',
        'view_all_pattern',
        'view_production_line',
        'create_installed_robot',
        'create_simulation',
        'edit_project',
        'modify_product',
        'expand_simulation',
        'view_pattern',
        'edit_product',
        'continue_to_customer',
        'save_product',
        'download_pallet_report',
        'view_product',
        'view_robot_configuration',
        'download_pdf_report',
        'view_hardware',
        'view_software',
        'modify_pattern',
        'pick_product_from_inventory',
        'clone_robot_configuration',
        'upload_robot_configuration',
        'upload_backup_files_robot_configuration',
        'modify_hardware_configuration',
        'create_waypoints',
        'create_calibration',
        'create_pally_path_strategy',
        'create_pally_program',
        'link_sw_license',
      ],
    },
    {
      role: 'org_view',
      actions: [
        'default_action',
        'view_project',
        'share_simulation',
        'download_pdf_report',
        'download_pallet_report',
        'insert_simulation_comment',
        'view_hardware',
        'view_software',
        'view_product',
        'view_robot_configuration',
        'view_simulation_details',
        'download_json_pattern',
        'view_simulation_values',
        'pallet_back',
        'view_all_organization',
        'view_all_robot_configuration',
        'view_all_organization_member',
        'view_all_production_line',
        'view_all_inventory',
        'customers_sort_by',
        'view_all_installed_robot',
        'view_all_project',
        'view_all_simulation',
        'view_all_product',
        'view_all_pattern',
        'view_production_line',
        'export_robot_configuration',
        'expand_simulation',
        'continue_to_customer',
        'customize_pattern',
        'play_simulation',
        'modify_pattern',
        'compare',
        'view_waypoints',
        'download_waypoints',
        'view_calibrations',
        'view_pally_path_strategies',
        'download_backup',
        'download_calibrations',
        'show_license',
      ],
    },
    {
      role: 'public',
      actions: [
        'default_action',
        'view_simulation_values',
        'share_simulation',
        'insert_simulation_comment',
      ],
    },
    {
      role: 'rf_backoffice',
      actions: actions.filter(
        (a) => a !== 'create_license_generation' && a !== 'view_licenses'
      ),
    },
    {
      role: 'rf_backoffice_license',
      actions: actions,
    },
    {
      role: 'user',
      actions: ['default_action'],
    },
  ];

  return actionsMap.find((m) => m.role === role)
    ? actionsMap.find((m) => m.role === role).actions
    : [];
};
