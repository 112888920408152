import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  Subject,
} from 'rxjs';
import { filter, first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ApiStrategy } from 'src/app/models_new/classes/api-models/ApiStrategy';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationService } from 'src/app/services/notification.service';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { ObjectUtils } from 'src/app/utils/object';
import { SortDirection } from '@angular/material/sort';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { DataRequestState } from '../../../data-request/model';
import { toRequestState } from '../../../data-request/operators';
import { ApiInstalledRobot } from '../../../models_new/classes/api-models/ApiRobotInstallation';
import { InstalledRobotsApiService } from '../../../services/api/robot-installations-api.service';
import { InvalidLicenseInfo } from '../../../utils/installed-robots-tools';
import { PickerType } from 'src/app/models_new/enums/picker-type';
import { IrLicenseComponent } from '../../dialogs/ir-license/ir-license.component';
import { pagesPATH } from 'src/app/models_new/config/pages';
import {
  InventoryTableCta,
  InventoryTableDisplayColumn,
  InvetoryTableAction,
} from '../../gui/inventory-table/inventory-table.component';
import { DatePipe } from '@angular/common';
import { HardwareUtils } from 'src/app/utils/hardware-utils';
import { RobotBackupAssistantService } from 'src/app/services/robot-backup-assistant.service';
import { StateService } from 'src/app/auth/state.service';
import { RobotBrandType } from 'src/app/models_new/enums/robot-brand-types';

@Component({
  selector: 'app-installedrobots',
  templateUrl: './installed-robots.component.html',
  styleUrls: ['./installed-robots.component.scss'],
})
export class InstalledRobotsComponent implements OnDestroy {
  displayedColumns: InventoryTableDisplayColumn[] = [
    { label: 'Name', path: 'name', sortType: 'string' },
    {
      label: 'License status',
      path: 'installed_robot_sw_license.status',
      sortType: 'string',
      specialCell: [
        {
          type: 'badge',
          color: (row: ApiInstalledRobot) => {
            if (row.installed_robot_sw_license.status === 'no-license') {
              return 'primary';
            } else if (row.installed_robot_sw_license.status === 'expired') {
              return 'warn';
            } else if (row.installed_robot_sw_license.status === 'active') {
              return 'accent';
            } else {
              return 'primary';
            }
          },
        },
      ],
    },
    { label: 'Robot type', path: 'robot_name', sortType: 'string' },
    {
      label: 'Pally programs',
      path: 'waypoints',
      sortType: 'number',
      specialCell: [
        {
          type: 'link',
          link: (_e: { name: string; id: string }) => {
            return '/' + pagesPATH.WAYPOINTS;
          },
        },
      ],
    },
    { label: 'Updated at', path: 'updated_at', sortType: 'date' },
  ];

  rowActions: InvetoryTableAction[] = [
    {
      label: 'Show license',
      roleAction: 'show_license',
      actionId: 'show_license',
    },
    {
      label: 'Export backup',
      roleAction: 'download_backup',
      actionId: 'download_backup',
      icon: 'download',
      disabled: (row: ApiInstalledRobot) => row.robot_brand !== 'UR',
    },
    {
      label: 'Export calibration',
      roleAction: 'download_backup',
      actionId: 'download_calibration',
      icon: 'download',
      disabled: (row: ApiInstalledRobot) => row.robot_brand !== 'DOOSAN',
    },
    {
      label: 'Duplicate',
      roleAction: 'create_installed_robot',
      actionId: 'duplicate',
      icon: 'file_copy',
      divideAfter: true,
    },
    {
      label: 'Delete',
      roleAction: 'delete_installed_robot',
      actionId: 'delete',
      icon: 'delete',
      color: 'warn',
    },
  ];

  rowCta: InventoryTableCta[] = [
    {
      label: 'Pally program',
      roleAction: 'create_pally_program',
      actionId: 'generate_waypoints',
      icon: 'add',
      disabled: (row: ApiInstalledRobot) => row.robot_brand !== 'DOOSAN',
    },
    {
      label: 'Simulation',
      roleAction: 'start_simulation',
      actionId: 'start_simulation',
      icon: 'add',
      disabled: (row: ApiInstalledRobot) => row.robot_brand !== 'UR',
    },
  ];

  org_id$: Observable<string>;
  robots$: Observable<DataRequestState<ApiInstalledRobot[]>>;
  robotsInvalidLicense$: Observable<InvalidLicenseInfo[]>;

  blockSelected: ApiInstalledRobot[] = [];
  objUtil = ObjectUtils;
  pickerType: PickerType = PickerType.ROBOT_LICENSE;

  sortBy$: BehaviorSubject<string> = new BehaviorSubject<string>('name');
  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };

  private destroy$: Subject<boolean> = new Subject<boolean>();

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'installed_robot_sw_license.status',
      label: 'License status',
    },
    {
      id: 'robot_name',
      label: 'Robot Type',
    },
    {
      id: 'updated_at',
      label: 'Updated at',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private installedRobotService: InstalledRobotsApiService,
    private dialogService: DialogService,
    private notifier: NotificationService,
    private robotBackupService: RobotBackupAssistantService,
    private datePipe: DatePipe,
    private stateService: StateService
  ) {
    // Remove "Pally programs" column if no "doosan" brand.
    this.stateService.activeBrands$.pipe(first()).subscribe({
      next: (brands: RobotBrandType[]) => {
        if (!brands || !brands.length) {
          return;
        }

        if (!brands.includes(RobotBrandType.DOOSAN)) {
          this.displayedColumns = this.displayedColumns.filter((column) => {
            return column.path !== 'waypoints';
          });
        }
      },
      error: (err) => {
        console.error(err);
      },
    });

    this.org_id$ = this.route.data.pipe(
      map(
        (data: Data) =>
          (data as IOrganizationContextResolverData).organization_id
      )
    );
    this.robots$ = this.org_id$.pipe(
      switchMap((org_id: string) =>
        this.installedRobotService.fetchOrgInstalledRobots(org_id)
      ),
      map((robots) => {
        robots.map((robot) => {
          robot.updated_at =
            this.datePipe.transform(robot.updated_at, 'dd/MM/yyyy HH:mm') ||
            'N/A';
          robot.robot_name = HardwareUtils.getRobotModelBySerialNumber(
            robot.robot_serial_number
          );
          robot.robot_brand = HardwareUtils.getRobotBrand(
            robot.robot_serial_number
          );

          // Remove "-" from license status
          robot.installed_robot_sw_license.status =
            robot.installed_robot_sw_license.status.replace(/-/g, ' ') as any;
        });
        return robots;
      }),
      toRequestState(),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public onDelete(): void {
    this.deleteRobotList(this.blockSelected);
  }

  public rowClicked(e: ApiStrategy): void {
    this.navigateToInstallation(e.id);
  }

  public ctaClicked(e: {
    action: InventoryTableCta;
    row: ApiInstalledRobot;
  }): void {
    if (e.action.actionId === 'generate_waypoints') {
      this.router.navigate([pagesPATH.WAYPOINTS, 'new']);
    }
    if (e.action.actionId === 'start_simulation') {
      return this.onSimulationClick(e.row.robot_configuration[0].id);
    }
  }

  public actionClicked(e: {
    action: InvetoryTableAction;
    row: ApiInstalledRobot;
  }): void {
    switch (e.action.actionId) {
      case 'delete':
        this.deleteRobotList([e.row]);
        break;
      case 'upload_robot_config_backup':
        this.uploadBackupFiles(e.row.id, e.row.name);
        break;
      case 'view_robot':
        this.navigateToInstallation(e.row.id);
        break;
      case 'download_backup':
        this.org_id$.pipe(take(1)).subscribe((org_id: string) => {
          this.robotBackupService.downloadBackupFiles(
            'UR',
            e.row.name,
            e.row.id,
            org_id
          );
        });
        break;
      case 'download_calibration':
        this.org_id$.pipe(take(1)).subscribe((org_id: string) => {
          this.robotBackupService.downloadBackupFiles(
            'DOOSAN',
            e.row.name,
            e.row.id,
            org_id
          );
        });
        break;
      case 'show_license':
        this.dialogService
          .showCustomDialog(IrLicenseComponent, DialogSize.MEDIUM, null, {
            robot: {
              installed_robot_sw_license: {
                robot_serial_number: e.row.robot_serial_number,
              },
            },
          })
          .afterClosed();
        break;
      case 'duplicate':
        this.duplicateRobotEntry(e.row);
        break;
      default:
        console.warn('Unknown action', e.action.actionId);
        break;
    }
  }

  private uploadBackupFiles(installed_robot_id: string, robot_name: string) {
    this.org_id$
      .pipe(
        take(1),
        switchMap((org_id: string) =>
          this.robotBackupService.uploadBackupGeneric({
            orgId: org_id,
            name: robot_name,
            updateRobotId: installed_robot_id,
          })
        )
      )
      .subscribe({
        next: () => {
          this.notifier.showSuccess('Backup files uploaded successfully.');
        },
        error: (err) => {
          this.notifier.showError(err.message);
        },
      });
  }

  newInstallation() {
    this.org_id$
      .pipe(
        take(1),
        switchMap((org_id: string) =>
          this.installedRobotService.insertInstalledRobotOne({
            name: 'New robot',
            organization_id: org_id,
          })
        )
      )
      .subscribe({
        next: (_res) => {
          this.notifier.showSuccess('New robot created successfully.');
        },
        error: (err) => {
          this.notifier.showError(err.message);
        },
      });
  }

  private duplicateRobotEntry(rowData: ApiInstalledRobot): void {
    combineLatest([
      this.org_id$,
      this.installedRobotService.fetchInstalledRobotsByID(rowData.id, false),
    ])
      .pipe(
        take(1),
        switchMap(([orgId, robot]) =>
          this.installedRobotService.insertInstalledRobotFromExistingEntry(
            robot.name + ' (copy)',
            orgId,
            robot.backup_zip_id,
            robot.calibration_id,
            robot.robot_configuration_id,
            robot.robot_serial_number,
            robot.mac_address
          )
        )
      )
      .subscribe({
        next: () =>
          this.notifier.showMessage('The robot was duplicated successfully!'),
        error: (err) => {
          this.notifier.showError(
            'Something went wrong, please try again later'
          );
          console.error(err);
        },
      });
  }

  private navigateToInstallation(id: string): void {
    this.router.navigate(['robot', id], {
      relativeTo: this.route,
    });
  }

  private deleteRobotList(rowData: ApiInstalledRobot[]): void {
    const dialogRef = this.notifier.deletePrompt(
      'Delete',
      'installation',
      rowData.map((m) => m['name'])
    );
    dialogRef
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          const operations = {};
          for (let i = 0; i < rowData.length; i++) {
            operations[i] = this.installedRobotService.deleteInstalledRobots(
              rowData[i].id
            );
          }
          return forkJoin(operations);
        }),
        take(1)
      )
      .subscribe({
        next: (_res) =>
          this.notifier.showMessage(
            `The installation were deleted successfully!`
          ),
        error: (err) => this.notifier.showError(err.message),
      });
  }

  private onSimulationClick(id: string): void {
    this.router.navigate([
      pagesPATH.SIMULATIONS,
      pagesPATH.INSTALLED_ROBOT_SIMULATION_WIZARD,
      id,
    ]);
  }
}
