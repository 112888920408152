import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  ApiStrategy,
  IApiStrategy,
} from 'src/app/models_new/classes/api-models/ApiStrategy';
import { SoftwareApiService } from 'src/app/services/api/software-api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DataRequestState } from '../../../../data-request/model';
import { toRequestState } from '../../../../data-request/operators';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { ObjectUtils } from 'src/app/utils/object';
import { SortDirection } from '@angular/material/sort';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { getConvertedLabelValue } from 'src/app/utils/unit-utils';
import {
  InventoryTableDisplayColumn,
  InvetoryTableAction,
} from 'src/app/components/gui/inventory-table/inventory-table.component';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-software-list',
  templateUrl: './software-list.component.html',
  styleUrls: ['./software-list.component.scss'],
})
export class SoftwareListComponent implements OnInit, OnDestroy {
  strategies$: Observable<DataRequestState<ApiStrategy[]>>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'polyscope_version',
      label: 'Robot OS',
    },
    {
      id: 'pally_version',
      label: 'Pally Software Version',
    },
    {
      id: 'gripper_optimization',
      label: 'Gripper Optimization',
    },
    {
      id: 'max_speed',
      label: 'Max Speed',
    },
    {
      id: 'max_acceleration',
      label: 'Max Acceleration',
    },
    {
      id: 'approach_distance',
      label: 'Approach Distance',
    },
  ];

  displayedColumns: InventoryTableDisplayColumn[] = [
    {
      label: 'Name',
      path: 'name',
      sortType: 'string',
    },
    {
      label: 'Robot OS',
      path: 'polyscope_version',
      sortType: 'string',
    },
    {
      label: 'Pally Software Version',
      path: 'pally_version',
      sortType: 'string',
    },
    {
      label: 'Speed / Acceleration (max)',
      path: 'max_speed_max_acceleration',
      sortType: 'number',
    },
    {
      label: 'Robot Configurations',
      path: 'robot_configurations',
      sortType: 'string',
      specialCell: [
        {
          type: 'link',
          link: (e: { name: string; id: string }) => {
            return `/${pagesPATH.INVENTORY}/${pagesPATH.ROBOT_CONFIGURATIONS}/${e.id}`;
          },
        },
      ],
    },
    {
      label: 'Updated At',
      path: 'updated_at',
      sortType: 'date',
    },
  ];

  rowActions: InvetoryTableAction[] = [
    {
      label: 'Duplicate',
      roleAction: 'create_software',
      actionId: 'duplicate_software',
      icon: 'content_copy',
    },
    {
      label: 'Delete',
      roleAction: 'delete_software',
      actionId: 'delete_software',
      icon: 'delete',
      color: 'warn',
    },
  ];

  objUtil = ObjectUtils;
  blockSelected: ApiStrategy[] = [];
  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private softwareApi: SoftwareApiService,
    private notifier: NotificationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.strategies$ = this.route.data.pipe(
      take(1),
      switchMap((data: Data) =>
        this.softwareApi.subscribeInventoryStrategies(
          (data as IOrganizationContextResolverData).organization_id
        )
      ),
      map((strategies: ApiStrategy[]) => {
        return strategies.map((strategy: ApiStrategy) => {
          strategy.max_speed = getConvertedLabelValue(
            strategy.max_speed + ' mm/s'
          );
          strategy.max_acceleration = getConvertedLabelValue(
            strategy.max_acceleration + ' mm/s²'
          );

          strategy.max_speed_max_acceleration = `${strategy.max_speed} / ${strategy.max_acceleration}`;

          strategy.updated_at =
            this.datePipe.transform(strategy.updated_at, 'dd/MM/yyyy HH:mm') ||
            ('N/A' as any);

          return strategy;
        });
      }),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public actionClicked(e: {
    action: InvetoryTableAction;
    row: ApiStrategy;
  }): void {
    // Favourite action
    // if (e.action.actionId === 'favourite') {
    //   this.strategies$
    //     .pipe(
    //       map((strategies) => strategies.find((sw) => sw.id === e.element.id)),
    //       switchMap((strategy) => {
    //         e.element.favourite = !e.element.favourite;
    //         strategy.data.favourite = !strategy.data.favourite;
    //         return this.softwareApi.updateStrategy(
    //           e.element.id,
    //           strategy.name,
    //           null,
    //           strategy.data
    //         );
    //       }),
    //       take(1)
    //     )
    //     .subscribe();
    // }
    // Delete action
    if (e.action.actionId === 'delete_software') {
      this.onDelete([e.row]);
    }
    if (e.action.actionId === 'duplicate_software') {
      this.onDuplicate(e.row);
    }
  }

  rowClicked(e: ApiStrategy) {
    this.router.navigate([pagesPATH.INVENTORY, pagesPATH.SOFTWARE_LIST, e.id]);
  }

  navigateToSoftware(id: string): void {
    this.router.navigate([id], {
      relativeTo: this.route,
    });
  }

  onDuplicate(strategyRow: ApiStrategy) {
    this.softwareApi
      .fetchStrategy(strategyRow.id)
      .pipe(
        take(1),
        switchMap((strategy: IApiStrategy) =>
          this.softwareApi.insertStrategy(
            strategy.name + ' (copy)',
            strategy.description,
            strategy.data,
            this.route.snapshot.data.organization_id
          )
        ),
        take(1)
      )
      .subscribe({
        next: (result) => {
          if (result === null) {
            this.notifier.showError(
              'Duplicate failed! Are you the owner of this item, and do you have the correct role in your organization?'
            );
          } else {
            this.notifier.showMessage(
              `The software configuration was duplicated successfully`
            );
          }
        },
      });
  }

  onDelete(elements: ApiStrategy[]) {
    this.notifier
      .deletePrompt(
        'Delete',
        'software configuration',
        elements.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          const operations = elements
            .map((m) => m.id)
            .map((id) => this.softwareApi.deleteStrategyById(id));
          return forkJoin(operations);
        })
      )
      .subscribe((result) => {
        if (result === null) {
          this.notifier.showError(
            'Delete failed! Are you the owner of this item, and do you have the correct role in your organization?'
          );
        } else {
          this.notifier.showMessage(
            `The software configuration was deleted successfully`
          );
        }
      });
  }
}
